<template>
  <div class="easy-address-select">
    <div class="easy-address-select__header">
      <div class="address-page__header">
        <span
          class="address-page__back"
          @click="onClose"
          v-html="leftArr"
        ></span>
        <div class="address-page__title">{{ $t("findAddressUSA") }}</div>
      </div>
      <div class="easy-address-select__search">
        <easy-input dark :label="$t('search')" @input="debounceSearch" />
      </div>
    </div>

    <div class="easy-address-select__content">
      <div v-if="isLoading" class="loader">
        <div class="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <div v-if="this.list" class="easy-address-select__list">
        <div v-if="this.list.length">
          <div
            v-for="item in list"
            :key="item.public_id"
            class="easy-address-select__item"
            @click="() => onSelectItem(item.public_id)"
          >
            {{ `${item.description}` }}
          </div>
        </div>
        <div class="easy-address-select__empty" v-else>
          {{ $t("noItem") }}
        </div>
      </div>
      <div v-if="this.detailsList" class="easy-address-select__list">
        <div v-if="this.detailsList.length">
          <div
            v-for="item in detailsList"
            :key="item.public_id"
            class="easy-address-select__item"
            @click="() => onSelectAddress(item.public_id)"
          >
            {{ `${item.description}` }}
          </div>
        </div>
        <div class="easy-address-select__empty" v-else>
          {{ $t("noItem") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { leftArr } from "@/services/svg";
import eventsRegister from "@/services/events-register";
import { mapGetters } from "vuex";
import {
  findAddressListGB,
  getAddressDetailsGB,
} from "@/services/api-connector.ts";
import EasyInput from "@/components/common/EasyInput.vue";

export default {
  name: "MyAddressSelectUnitedKingdom",
  components: { EasyInput },
  props: {
    onSelect: {
      type: Function,
      default: () => true,
    },
    onClose: {
      type: Function,
      default: () => true,
    },
  },
  data() {
    return {
      leftArr,

      filterValue: "",
      isLoading: false,
      debounce: null,
      list: null,
      detailsList: null,
    };
  },
  computed: {
    ...mapGetters("user", ["userAddressCountry"]),
  },
  methods: {
    debounceSearch(event) {
      this.isLoading = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        const addressList = await findAddressListGB(
          event,
          this.userAddressCountry
        );
        this.isLoading = false;
        this.list = addressList;
      }, 600);
    },
    async onSelectItem(publicId) {
      const details = await getAddressDetailsGB(
        publicId,
        this.userAddressCountry
      );

      if (!details.address) {
        return this.$root.$emit(eventsRegister.addMessage, {
          type: "error",
          message: this.$t("serverError"),
          autoClose: true,
        });
      }

      if (!details.addresses) {
        return this.onSelect(details.address);
      }

      this.detailsList = details.addresses;
      this.list = null;
    },
    async onSelectAddress(publicId) {
      const details = await getAddressDetailsGB(
        publicId,
        this.userAddressCountry
      );

      if (!details.address) {
        return this.$root.$emit(eventsRegister.addMessage, {
          type: "error",
          message: this.$t("serverError"),
          autoClose: true,
        });
      }

      this.onSelect(details.address);
    },
  },
};
</script>

<style lang="scss">
.easy-address-select {
  position: fixed;
  top: 0;
  top: env(safe-area-inset-top);
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 22;
  background: #fff;
  display: flex;
  flex-direction: column;

  &__search {
    background: #282828;
    padding-left: 16px;
    padding-right: 16px;
  }
  .dark.easy-input {
    background: #282828;
  }

  &__list {
    max-height: calc(100vh - 120px - env(safe-area-inset-top));
    overflow: auto;
    padding-bottom: 40px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__content {
    position: relative;
    flex: 1 auto;
    display: flex;
  }

  &__empty {
    text-align: center;
    color: #282828;
    font-size: 20px;
    display: flex;
    flex: 1 auto;
    align-items: center;
    justify-content: center;
  }

  &__item {
    color: #282828;
    min-height: 48px;
    display: flex;
    align-items: center;
    padding-left: 16px;
    box-sizing: border-box;
    padding-top: 2px;
    padding-right: 50px;
    position: relative;

    &.isSelected:after {
      content: "";
      position: absolute;
      background: #91c258 url("~@/assets/img/i-success.svg") center center
        no-repeat;
      background-size: 10px;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      right: 16px;
      top: 0;
      bottom: 0;
      margin: auto;
    }

    &:nth-child(2n) {
      background: #f8f8f8;
    }
  }
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 10;
  background: #fff;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 44px;
  height: 44px;
  margin: 8px;
  border: 6px solid #fab314;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fab314 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
