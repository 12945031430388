<template>
  <div class="address-page address-page--new">
    <div class="address-page__header is-fixed">
      <span @click="onBack" class="address-page__back" v-html="leftArr"></span>
      <div class="address-page__title">{{ $t("addressNew") }}</div>
    </div>
    <div class="address-page__content">
      <form
        id="address-new-form"
        method="post"
        novalidate="true"
        class="address-page__form"
        @submit="onSubmit"
      >
        <easy-input
          v-model="name"
          :label="$t('addressNameANPu')"
          :onEnter="onSubmit"
          :errorText="$t('oldPasswordError')"
          :isError="isSubmitted && !name"
          isRequired
        />
        <div class="address-page__form-title">{{ $t("myAddressTo") }}</div>

        <div v-if="userIsFromUSA">
          <easy-phantom-input
            :currentValue="selectedAddressValueUSA"
            :label="$t('findAddressUSA')"
            :errorText="$t('oldPasswordError')"
            :isError="isSubmitted && !selectedAddressValueUSA"
            :onClick="() => openFindAddress()"
          />
          <easy-input
            v-model="details"
            :label="$t('addressApartmentName')"
            :onEnter="onSubmit"
          />
        </div>

        <div v-if="!userIsFromUSA">
          <div class="address-page__form-type">
            <label class="address-page__form-checkbox">
              <input
                type="radio"
                value="local"
                name="address-type"
                v-model="type"
              />
              <span>{{ $t("carierANPu") }}</span>
            </label>
            <label class="address-page__form-checkbox">
              <input
                type="radio"
                value="newpost"
                name="address-type"
                v-model="type"
              />
              <span>{{ $t("toPostDeptANPu") }}</span>
            </label>
          </div>
          <easy-phantom-input
            :currentValue="selectedState.name"
            :label="$t('regionANA')"
            :errorText="$t('oldPasswordError')"
            :isError="isSubmitted && !state"
            :onClick="() => openStateSelect()"
          />
          <easy-phantom-input
            :currentValue="selectedCity.name"
            :label="$t('city')"
            :errorText="$t('oldPasswordError')"
            :isError="isSubmitted && !city"
            :onClick="() => openCitySelect()"
          />
          <div
            class="address-page__form-newpost"
            :class="{ isError: isSubmitted && !this.newPost }"
            v-if="type === 'newpost'"
            @click="openPostSelect()"
          >
            <img
              class="address-page__form-newpost__icon"
              src="@/assets/img/Choosing/NP.svg"
            />
            <div class="address-page__form-newpost__title">
              {{ $t("postDeptANPu") }}
            </div>
            <div class="address-page__form-newpost__value">
              {{ selectedPost.name || $t("myAddressEmptySelect") }}
            </div>
            <img
              class="address-page__form-newpost__right-icon"
              src="@/assets/img/parcel/ArrRight.svg"
            />
          </div>
          <easy-phantom-input
            v-if="type === 'local'"
            :currentValue="selectedStreet.name"
            :label="$t('street')"
            :errorText="$t('oldPasswordError')"
            :isError="isSubmitted && !street"
            :onClick="() => openStreetSelect()"
          />
          <div class="easy-input__group" v-if="type === 'local'">
            <easy-input
              v-model="build"
              :label="$t('houseNumberANA')"
              :errorText="$t('build')"
              :isError="isSubmitted && !build"
              isRequired
            />
            <easy-input
              v-model="flat"
              :label="$t('apartmentANA')"
              :errorText="$t('flat')"
              :isError="isSubmitted && !flat"
              isRequired
            />
          </div>
          <easy-input
            v-if="type === 'local'"
            v-model="zipCode"
            :label="$t('zipPostalCode')"
            :onEnter="onSubmit"
            :errorText="$t('oldPasswordError')"
            :isError="isSubmitted && !zipCode"
            isRequired
          />
        </div>

        <div class="address-page__form-title">
          {{ $t("myAddressAboutRecipient") }}
        </div>
        <easy-input
          v-model="firstName"
          :label="$t('firstName')"
          :onEnter="onSubmit"
          :errorText="$t('oldPasswordError')"
          :isError="isSubmitted && !firstName"
          isRequired
        />
        <easy-input
          v-model="lastName"
          :label="$t('lastName')"
          :onEnter="onSubmit"
          :errorText="$t('oldPasswordError')"
          :isError="isSubmitted && !lastName"
          isRequired
        />
        <easy-input
          v-model="phoneNumber"
          :label="$t('phoneReg')"
          defaultType="tel"
          isPhoneNumber
          :mask="phoneMask"
          :onEnter="onSubmit"
          :errorText="$t('errPhone')"
          :isError="isSubmitted && this.unmaskedPhoneNumber.length < 9"
          isRequired
        />
        <button
          :class="{ disabled: isNotValid, isLoading: isLoading }"
          class="btnWideRound"
        >
          {{ $t("btnSave") }}
        </button>
      </form>
    </div>
    <my-address-select
      v-if="isStateSelectOpened"
      :list="stateList"
      :selectedItem="state"
      :title="$t('myAddressStateTitle')"
      :onSelect="onSelectState"
      :onClose="() => (isStateSelectOpened = false)"
    />
    <my-address-select
      v-if="isCitySelectOpened"
      :list="cityList"
      :selectedItem="city"
      :title="$t('myAddressCityTitle')"
      :onSelect="onSelectCity"
      :onClose="() => (isCitySelectOpened = false)"
    />
    <my-address-select
      v-if="isStreetSelectOpened"
      :list="streetList"
      :selectedItem="street"
      :title="$t('streetANPu')"
      :onSelect="onSelectStreet"
      :onClose="() => (isStreetSelectOpened = false)"
    />
    <my-address-select
      v-if="isPostSelectOpened"
      :list="postDataList"
      :selectedItem="newPost"
      :title="$t('postDeptANPu')"
      :onSelect="onSelectPost"
      :onClose="() => (isPostSelectOpened = false)"
    />

    <my-address-select-usa
      v-if="isFindOpenedUSA"
      :onSelect="onFindOpenedUSA"
      :onClose="() => (isFindOpenedUSA = false)"
    />

    <my-address-select-united-kingdom
      v-if="isFindOpenedUK"
      :onSelect="onFindOpenedUK"
      :onClose="() => (isFindOpenedUK = false)"
    />
  </div>
</template>

<script>
import { leftArr } from "@/services/svg";
import eventsRegister from "@/services/events-register";
import EasyInput from "@/components/common/EasyInput.vue";
import EasyPhantomInput from "@/components/common/EasyPhantomInput.vue";
import MyAddressSelect from "./MyAddressSelect.vue";
import MyAddressSelectUsa from "./MyAddressSelectUsa.vue";
import MyAddressSelectUnitedKingdom from "./MyAddressSelectUK.vue";
import { mapActions, mapGetters } from "vuex";

// interface Address {
//   Formatted: string;
//   AddressLine1: string;
//   HouseNumber: string;
//   PostalOrZip: string;
//   Street: string;
//   Country: {
//     Name: string;
//     CodeAlpha2: string;
//   };
// }

// interface Location {
//   formatted_address: string;
//   Address: Address;
// }

const deliveryTypes = {
  local: 11212,
  newpost: 11028,
};

export default {
  name: "MyAddressNew",
  components: {
    EasyInput,
    EasyPhantomInput,
    MyAddressSelect,
    MyAddressSelectUsa,
    MyAddressSelectUnitedKingdom,
  },
  data() {
    return {
      leftArr,

      name: "",
      type: "local",
      state: null,
      city: null,
      street: null,
      build: "",
      flat: "",
      zipCode: "",
      details: "",
      newPost: null,
      firstName: "",
      lastName: "",
      phoneNumber: "",
      selectedAddressUSA: null,
      selectedAddressUK: null,

      phoneMask: {
        mask: "+PPPPPPPPPPPPP",
        tokens: {
          P: { pattern: /^[0-9]*\.?[0-9]*$/ },
          B: { pattern: /^([1-9][0-9]{0,1})$/ },
        },
      },

      nameMask: {
        mask: "CCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCC",
        tokens: {
          C: {
            pattern: /^[аАбБвВгГдДеЕёЁжЖзЗіІїЇиҐґИйЙкКл-єЄЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯ]+$/,
          },
        },
      },

      isSubmitted: false,
      isLoading: false,
      isStateSelectOpened: false,
      isCitySelectOpened: false,
      isStreetSelectOpened: false,
      isPostSelectOpened: false,
      isFindOpenedUSA: false,
      isFindOpenedUK: false,
    };
  },
  computed: {
    ...mapGetters("address", [
      "stateList",
      "cityList",
      "streetList",
      "postDataList",
      "stateById",
      "cityById",
      "streetById",
      "postById",
    ]),
    ...mapGetters("user", ["user", "userCountryCode"]),
    isNotValid() {
      const checkForUSA =
        !this.name.trim() ||
        !this.selectedAddressUSA ||
        !this.firstName.trim() ||
        !this.lastName.trim() ||
        this.unmaskedPhoneNumber.length < 9;

      const defaultCheck =
        !this.name.trim() ||
        !this.state ||
        !this.city ||
        !this.firstName.trim() ||
        !this.lastName.trim() ||
        this.unmaskedPhoneNumber.length < 9;

      const localCheck =
        !this.street || !this.build || !this.flat || !this.zipCode;
      const postCheck = !this.newPost;

      const defaultAddressCheck =
        this.type === "local" ? localCheck : postCheck;
      const checkForUkraine = defaultCheck || defaultAddressCheck;

      return this.userIsFromUSA ? checkForUSA : checkForUkraine;
    },
    selectedAddressValueUSA() {
      if (!this.selectedAddressUSA) {
        return "";
      }

      if (this.selectedAddressUSA.formatted) {
        return this.selectedAddressUSA.formatted;
      }

      const parts = [
        this.selectedAddressUSA.city_name,
        this.selectedAddressUSA.street_line,
        this.selectedAddressUSA.state_abbreviation,
        this.selectedAddressUSA.zip,
      ];

      // Filter out any undefined or null values
      const validParts = parts.filter((part) => part != null && part !== "");

      // Join the valid parts with a comma and a space
      return validParts.join(", ");
    },
    selectedState() {
      return this.stateById(this.state);
    },
    selectedCity() {
      return this.cityById(this.city);
    },
    selectedStreet() {
      return this.streetById(this.street);
    },
    selectedPost() {
      return this.postById(this.newPost);
    },
    unmaskedPhoneNumber() {
      return this.phoneNumber.replace(/^(\+)|\D/g, "$1");
    },
    userIsFromUSA() {
      return [284, 301, 308].includes(this.user.pr201);
    },
  },
  async created() {
    await this.loadStateList();
    this.firstName = this.user.pr_222 || "";
    this.phoneNumber = this.user.st_11010 || "";
  },
  methods: {
    ...mapActions("address", [
      "loadPostDepartments",
      "loadStateList",
      "loadCityList",
      "loadStreetList",
      "loadPostDepartments",
      "createAddress",
      "createAddressUSA",
      "getAddressList",
    ]),
    onBack() {
      this.$router.push("/");
    },
    openStateSelect() {
      this.isStateSelectOpened = true;
    },
    openFindAddress() {
      switch (this.userCountryCode) {
        case "USA":
          this.isFindOpenedUSA = true;
          break;
        case "GB":
        case "CA":
          this.isFindOpenedUK = true;
          break;
        default:
          break;
      }
    },
    async onSelectState(id) {
      await this.loadCityList(id);
      this.isStateSelectOpened = false;
      this.state = id;
      this.city = 0;
      this.street = 0;
      this.newPost = 0;
    },
    openCitySelect() {
      if (!this.state) {
        return this.$root.$emit(eventsRegister.addMessage, {
          type: "error",
          message: this.$t("chooseRegionANPu"),
        });
      }
      this.isCitySelectOpened = true;
    },
    async onSelectCity(id) {
      await Promise.all([
        this.loadStreetList(id),
        this.loadPostDepartments(id),
      ]);
      this.isCitySelectOpened = false;
      this.street = 0;
      this.newPost = 0;
      this.city = id;
    },
    openStreetSelect() {
      if (!this.city) {
        return this.$root.$emit(eventsRegister.addMessage, {
          type: "error",
          message: this.$t("chooseCityANPu"),
        });
      }
      this.isStreetSelectOpened = true;
    },
    onSelectStreet(id) {
      this.isStreetSelectOpened = false;
      this.street = id;
    },
    openPostSelect() {
      if (!this.state) {
        return this.$root.$emit(eventsRegister.addMessage, {
          type: "error",
          message: this.$t("chooseCityANPu"),
        });
      }
      this.isPostSelectOpened = true;
    },
    onSelectPost(id) {
      this.isPostSelectOpened = false;
      this.newPost = id;
    },
    onFindOpenedUSA(address) {
      this.selectedAddressUSA = address;
      this.isFindOpenedUSA = false;
    },
    onFindOpenedUK(address) {
      this.selectedAddressUSA = {
        street_line: address.formatted ?? "",
        city_name: address.locality ?? "",
        zip: address.postal_or_zip ?? "",
        build: address.house_number ?? "",
        formatted: address.formatted,
      };
      this.isFindOpenedUK = false;
    },
    async onSubmit(e) {
      e.preventDefault();
      if (this.isLoading) return false;
      this.isSubmitted = true;

      if (!this.isNotValid) {
        this.isLoading = true;

        const data = {
          addressName: this.name,
          firstName: this.firstName,
          lastName: this.lastName,
          phoneNumber: this.unmaskedPhoneNumber,
          deliveryType: deliveryTypes[this.type],
          userCountry: this.user.pr201,
        };

        if (this.userIsFromUSA) {
          data.street = this.selectedAddressUSA.street_line;
          data.city = this.selectedAddressUSA.city_name;
          data.zipCode = this.selectedAddressUSA.zip;
          data.state = this.selectedAddressUSA.state_abbreviation;
          data.houseNumber = this.selectedAddressUSA.build;
          data.details = this.details;
        } else {
          data.street =
            this.type === "local" ? this.street : this.streetList[0].id;
          data.zipCode = this.zipCode || "01001";
          data.city = this.city;
          data.state = this.state;
          data.postDepartment = this.newPost;
          data.build = this.build;
          data.flat = this.flat;
        }

        try {
          if (this.userIsFromUSA) {
            await this.createAddressUSA(data);
          } else {
            await this.createAddress(data);
          }

          await this.getAddressList();
          this.isLoading = false;
          this.$root.$emit(eventsRegister.addMessage, {
            type: "success",
            message: this.$t("newAddressSavedANPu"),
          });
          this.onBack();
          //   this.$router.push("/sign-up/confirm");
        } catch (error) {
          console.log("errorr", error);
          const message = this.$t("serverError");
          this.$root.$emit(eventsRegister.addMessage, {
            type: "error",
            message,
          });
          this.isLoading = false;
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/views/_address.scss";

.address-page {
  &--new .address-page__content {
    padding-top: 62px;
  }
  &__form {
    position: relative;
    flex: 1 auto;
    box-sizing: border-box;
    padding-bottom: 40px;
    margin-left: -16px;
    margin-right: -16px;
    margin-top: -24px;
    background: #fff;

    .easy-input,
    .easy-input__group,
    .btnWideRound {
      max-width: calc(100% - 32px);
      margin-left: auto;
      margin-right: auto;
    }
    .btnWideRound {
      margin-top: 10px;
    }
  }
  &__form-title {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 18px;
    padding-bottom: 16px;
    background: #f1f1f1;
    font-family: "Helvetica Neue Medium";
  }

  &__form-checkbox {
    position: relative;
    display: block;
    box-sizing: border-box;
    border-bottom: 1px solid #f1f1f1;
    margin-left: 56px;

    &:last-child {
      border-bottom: 1px solid transparent;
    }
    input {
      position: absolute;
      left: -9999px;
    }

    span {
      flex: 1 auto;
      color: #282828;
      margin-right: 16px;
      min-height: 61px;
      display: flex;
      align-items: center;
      position: relative;
      padding-top: 2px;
    }

    span:after {
      display: block;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 2px solid #bfbfbf;
      content: "";
      position: absolute;
      left: -40px;
      top: 0;
      bottom: 0;
      margin: auto;
    }
    span:before {
      display: block;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background: #91c258 url("~@/assets/img/i-success.svg") center center
        no-repeat;
      background-size: 8px;
      z-index: 2;
      content: "";
      position: absolute;
      left: -40px;
      top: 0;
      bottom: 0;
      margin: auto;
      opacity: 0;
      transition: 0.3s;
    }

    input:checked + span:before {
      opacity: 1;
    }
  }

  &__form-newpost {
    padding-left: 56px;
    padding-right: 40px;
    padding-bottom: 20px;
    padding-top: 10px;
    min-height: 68px;
    box-sizing: border-box;
    display: flex;
    position: relative;
    flex-direction: column;

    &__icon {
      position: absolute;
      top: 14px;
      left: 16px;
    }

    &__title {
      color: #696969;
      font-size: 12px;
      line-height: 1;
      margin-bottom: 8px;
    }

    &__value {
      color: #282828;
      font-size: 16px;
      line-height: 1;

      .isError & {
        color: #f95757;
      }
    }

    &__right-icon {
      position: absolute;
      right: 12px;
      top: 14px;
    }
  }
}
</style>
