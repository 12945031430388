<template>
  <div class="easy-address-select">
    <div class="easy-address-select__header">
      <div class="address-page__header">
        <span
          class="address-page__back"
          @click="onClose"
          v-html="leftArr"
        ></span>
        <div class="address-page__title">{{ title }}</div>
      </div>
      <div class="easy-address-select__search">
        <easy-input dark :label="$t('search')" v-model.lazy="filterValue" />
      </div>
    </div>
    <div v-if="this.list" class="easy-address-select__list">
      <div v-if="this.filteredList.length">
        <div
          v-for="item in filteredList"
          :key="item.id"
          class="easy-address-select__item"
          :class="{ isSelected: item.id === selectedItem }"
          @click="() => onSelect(item.id)"
        >
          {{ item.name }}
        </div>
      </div>
      <div v-else>
        {{ $t("noItem") }}
      </div>
    </div>
  </div>
</template>

<script>
import { leftArr } from "@/services/svg";
import EasyInput from "@/components/common/EasyInput.vue";
import { startsWith } from "@/utils/utils";

export default {
  name: "MyAddressSelect",
  components: { EasyInput },
  props: {
    title: String,
    isNewPost: Boolean,
    list: Array,
    selectedItem: {
      type: Number,
      default: 0,
    },
    onSelect: {
      type: Function,
      default: () => true,
    },
    onClose: {
      type: Function,
      default: () => true,
    },
  },
  computed: {
    filteredList() {
      if (!this.list) {
        return [];
      }
      const filteredValue = `${this.filterValue}`.toLowerCase();
      const listByStartWith = this.list.filter((itm) =>
        startsWith(itm.name.toLowerCase(), filteredValue)
      );
      const listByContains = this.list.filter((itm) =>
        itm.name.toLowerCase().includes(filteredValue)
      );
      return [...new Set([...listByStartWith, ...listByContains])];
    },
  },
  data() {
    return {
      leftArr,

      filterValue: "",
    };
  },
};
</script>

<style lang="scss">
.easy-address-select {
  position: fixed;
  top: 0;
  top: env(safe-area-inset-top);
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 22;
  background: #fff;

  &__search {
    background: #282828;
    padding-left: 16px;
    padding-right: 16px;
  }
  .dark.easy-input {
    background: #282828;
  }

  &__list {
    max-height: calc(100vh - 120px - env(safe-area-inset-top));
    overflow: auto;
    padding-bottom: 40px;
    box-sizing: border-box;
  }

  &__item {
    color: #282828;
    min-height: 48px;
    display: flex;
    align-items: center;
    padding-left: 16px;
    box-sizing: border-box;
    padding-top: 2px;
    padding-right: 50px;
    position: relative;

    &.isSelected:after {
      content: "";
      position: absolute;
      background: #91c258 url("~@/assets/img/i-success.svg") center center
        no-repeat;
      background-size: 10px;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      right: 16px;
      top: 0;
      bottom: 0;
      margin: auto;
    }

    &:nth-child(2n) {
      background: #f8f8f8;
    }
  }
}
</style>
